import React from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import { isMobileDevice } from "responsive-react";

import "./nav-button.scss";

const NavButton = ({ label, to, download, children, onClick }) => {
  // const linkProps = download
  //   ? {
  //       to: { pathname: to },
  //       target: "_blank",
  //     }
  //   : { to, activeClassName: "bc-nav-button__active", exact: true };

  return download ? (
    <Link className="bc-nav-button uppercase" to={to} target="_blank">
      <div className="bc-nav-button__content">
        <span className={clsx({ mobile: isMobileDevice() })}>{label}</span>
      </div>
    </Link>
  ) : (
    <NavLink
      to={to}
      // activeClassName="bc-nav-button__active"
      className={({ isActive }) =>
        isActive
          ? "bc-nav-button uppercase bc-nav-button__active"
          : "bc-nav-button uppercase"
      }
      // exact="true"
    >
      <div className="bc-nav-button__content">
        <span className={clsx({ mobile: isMobileDevice() })}>
          {label || children}
        </span>
      </div>
    </NavLink>
  );
};

NavButton.defaultProps = {
  download: false,
};

NavButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  to: PropTypes.string.isRequired,
  download: PropTypes.bool,
};

export default NavButton;
